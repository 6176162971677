/* eslint-disable max-len */
export const validateEmailHandler = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const validatePhoneHandler = (phone: string) => {
  const re = /^\+?(1\s?)?[\s\-]?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$/g;
  return re.test(phone.toLowerCase());
};

export const validatePasswordHandler = (passwor: string) => {
  return passwor.length >= 8;
};
