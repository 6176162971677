import { types, applySnapshot, flow, Instance, SnapshotIn } from "mobx-state-tree";
import { UserFormModel } from "../createUser/user.model";
import { InitialState } from "./initial.state";
import { validateEmailHandler, validatePhoneHandler } from "../../services/handlers/validate.handler";
import { inviteAdminApi, updateAdminApi, updatePhysicianNPI } from "../../services/api/administration.api";

export const InviteAdminModel = types
  .model("InviteAdminModel", {
    show: types.optional(types.boolean, false),
    isEdit: types.optional(types.boolean, false),
    idUser: types.optional(types.string, ""),
    id: types.identifier,
    name: types.maybe(UserFormModel),
    lastName: types.maybe(UserFormModel),
    comment: types.optional(types.string, "."),
    email: types.maybe(UserFormModel),
    isPhysician: types.maybe(UserFormModel),
    phone: types.maybe(UserFormModel),
    description: types.maybe(UserFormModel),
    phone2: types.maybe(UserFormModel),
    statReport: types.maybe(UserFormModel),
    sumReport: types.maybe(UserFormModel),
    npi: types.maybe(UserFormModel),
    IDPhysician: types.optional(types.number, 0),
    adminOldData: types.optional(types.frozen(), {})
  })
  .actions(self => {
    const resetForm = () => {
      self.isEdit = false;
      self.IDPhysician = 0;
      self.adminOldData = {};
      self.idUser = "";
      applySnapshot(self, InitialState);
    };

    const openModal = (data?: any, idExistUser?: any) => {
      const getValidPhoneNumber = (value: string | undefined) => {
        if (!value) return "";
        const formattedValue = value.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
        return formattedValue;
      };

      if (data) {
        self.isEdit = true;
        self.idUser = data.Id.toString();
        self.IDPhysician = data.IDPhysician;
        self.adminOldData = data;
        if (self.name) {
          self.name.value = data.Name || "";
        }
        if (self.lastName) {
          self.lastName.value = data.LastName || "";
        }
        if (self.comment && data.Comment) {
          self.comment = data.Comment;
        }
        if (self.npi) {
          self.npi.value = data.NPI ? data.NPI.toString() : "";
        }
        if (self.email) {
          self.email.value = data.Email || "";
          if (idExistUser.toString() !== data.Id.toString()) {
            self.email.setDisabled(true);
          }
        }
        if (self.isPhysician) {
          self.isPhysician.value = data.IsPhysician ? "true" : "false";
          self.isPhysician.isChecked = !!data.IsPhysician;
        }
        if (self.phone) {
          self.phone.value = data.Phone && getValidPhoneNumber(data.Phone) || "";
          if (idExistUser.toString() !== data.Id.toString()) {
            self.phone.setDisabled(true);
          }
        }
        if (self.phone2) {
          self.phone2.value = data.Phone2 && getValidPhoneNumber(data.Phone2) || "";
          if (idExistUser.toString() !== data.Id.toString()) {
            self.phone2.setDisabled(true);
          }
        }
        if (self.description) {
          self.description.value = data.Description && getValidPhoneNumber(data.Description) || "";
          if (idExistUser.toString() !== data.Id.toString()) {
            self.description.setDisabled(true);
          }
        }
        if (self.statReport) {
          self.statReport.value = data.IsStatNotification ? "true" : "false";
          self.statReport.isChecked = !!data.IsStatNotification;
        }
        if (self.sumReport) {
          self.sumReport.value = data.IsSummaryNotification ? "true" : "false";
          self.sumReport.isChecked = !!data.IsSummaryNotification;
        }
      } else {
        self.isEdit = false;
      }
      self.show = true;
    };

    const closeModal = () => {
      self.show = false;
      resetForm();
    };

    const validateFormFields = (isPhysicianNPINumber?: boolean): {
      errors: {[key: string]: string}, isValid: boolean
    } => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.name?.value) {
        errors.name = "Please fill the field";
        isValid = false;
      }

      if (!self.lastName?.value) {
        errors.lastName = "Please fill the field";
        isValid = false;
      }

      if (!self.email?.value) {
        errors.email = "Please fill the field";
        isValid = false;
      } else {
        if (!validateEmailHandler(self.email?.value)) {
          errors.email = "Wrong email";
          isValid = false;
        }
      }

      if (!self.phone?.value) {
        errors.phone = "Please fill the field";
        isValid = false;
      } else {
        if (!validatePhoneHandler(self.phone?.value)) {
          errors.phone = "Please enter valid phone number";
          isValid = false;
        }
      }

      if (isPhysicianNPINumber
        && self.isPhysician?.value === "true"
        && self.npi?.value
        && self.npi?.value.length !== 10
      ) {
        errors.npi = "Need 10 digit number";
        isValid = false;
      }

      return {
        errors,
        isValid
      };
    };

    const getDataForSave = () => {
      const getValidPhoneNumber = (value: string) => {
        return "+" + value.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
      };
      const data: any = {
        PortalURL: `${window.location.origin}/create-password/`,
        Name: self.name && self.name.value,
        LastName: self.lastName && self.lastName.value,
        Email: self.email && self.email.value,
        Comment: self.comment || ".",
        IsPhysician: self.isPhysician && self.isPhysician.value,
        IDPhysician: self.IDPhysician,
        Phone: self.phone && getValidPhoneNumber(self.phone.value),
        Phone2: self.phone2 && self.phone2.value && getValidPhoneNumber(self.phone2.value) || "",
        Description: self.description && self.description.value && getValidPhoneNumber(self.description.value) || ""
      };

      if (data.IsPhysician === "true") {
        data.IsStatNotification = self.statReport && self.statReport.value;
        data.IsSummaryNotification = self.sumReport && self.sumReport.value;
      }

      return data;
    };

    const saveForm = flow(function* saveForm(isPhysicianNPINumber?: boolean) {
      const data = getDataForSave();

      if (self.isEdit) {
        data.Id = self.idUser;

        if (data.IsPhysician === "true") {
          if (self.adminOldData && self.adminOldData.IsPhysician) {
            data.IsSetToPhysician = "true";
          } else if (self.adminOldData && !self.adminOldData.IsPhysician) {
            data.IsPhysician = "false";
            data.IsSetToPhysician = "true";
          }
        } else {
          data.IsSetToPhysician = "false";
        }
        const str = JSON.stringify(data);

        try {
          const response = yield updateAdminApi(str);
          if (response.ok && response.data) {
            if (isPhysicianNPINumber && response.data.Id) {
              yield updatePhysicianNPI(response.data.Id, self.npi?.value || "");
            }
            return true;
          }
          return false;
        } catch (error) {
          return false;
        }
      } else {
        const str = JSON.stringify(data);

        try {
          const response = yield inviteAdminApi(str);
          if (response.ok && response.data) {
            if (isPhysicianNPINumber && response.data.Id) {
              yield updatePhysicianNPI(response.data.Id, self.npi?.value || "");
            }
            return true;
          }
          return false;
        } catch (error) {
          return false;
        }
      }
    });

    return {
      openModal,
      closeModal,
      saveForm,
      validateFormFields
    };
  });

export interface IInviteAdminModel extends Instance<typeof InviteAdminModel> {}
export interface IInviteAdminModelSnapShot extends SnapshotIn<typeof InviteAdminModel> {}
