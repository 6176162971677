import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLUploadInsuranceCardImagesWrapper = styled.div`
  position: relative;
  .title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.text};
  }

  .form-container {
    .upload-container {
      display: flex;
      justify-content: flex-start;
      margin-top: 40px;
      outline: none;
      position: static;
      
      label {
        margin-bottom: 0px;
      }

      input {
        outline: none;
      }

      .selected-error {
        border: 2px solid ${theme.colors.pink};
      }
    }
    .upload-content {
      min-width: 583px;
      border-radius: 20px;
      border: 2px dashed ${theme.colors.uploadBorder};
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 40px;
      &.error {
        border: 2px dashed ${theme.colors.red};
      }
      &.selected {
        justify-content: flex-start;
        border: none;
      }

        .content {
          width: 400px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: ${theme.colors.text};
            text-align: center;
            margin-top: 16px;
          }
          .additional-text {
            display: inline-block;
            margin-top: 16px;
            line-height: 18px;
            color: #7D7D7D;
          }

          .error {
            color: ${theme.colors.red};
          }

          .m-b-40 {
            margin-bottom: 40px;
          }

          .devider {
            display: flex;
            align-items: center;
            gap: 18px;
            margin-top: 16px;
            margin-bottom: 16px;
            width: 100%;

            label {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              color: ${theme.colors.darkGreen};
              margin: 0;
            }

            div {
              height: 1px;
              background-color: ${theme.colors.greyDivider};
              flex: 1;
            }
          }

          .progressbar {
            width: 100px;
            height: 100px;
          }
        }
      }

    .alert-container {
      border-radius: 18px;
      border: 2px solid ${theme.colors.pink};
      padding: 24px;
      height: 140px;
      width: 600px;
      margin: auto;
      margin-top: 16px;
      overflow-y: scroll;
      position: relative;

      &::-webkit-scrollbar {
        width: 16px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: ${theme.colors.pink};
        border-radius: 0 10px 10px 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #F9C5C5;
        border: 4px solid transparent;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      .alert-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: ${theme.colors.red};
      }
    }

    .help-container {
      color: ${theme.colors.text};
      margin-top: 32px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      
      .text {
        width: 270px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        a {
          color: ${theme.colors.text};
          text-decoration: underline;
        }
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;

      div:last-child {
        margin-left: 24px;
      }
    }
  }
  
  .file-wrapper {
    background: #F3F3F3;
    border-radius: 8px;
    margin-right: 16px;
    width: 146px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .remove {
      margin-left: auto;
      width: 24px;
      height: 24px;
      background: #FFF;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: #457EF5;
        path {
          fill: #FFF;
        }
      }
    }
    .add-file {
      .icon {
        margin: 0 auto 8px;
        width: 48px;
        height: 48px;
        background: #FFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: #457EF5;
          path {
            fill: #FFF;
          }
        }
      }
      p {
        width: 80px;
        text-align: center;
        margin: 0 auto;
      }
    }
    &:hover {
      .icon {
        background: #457EF5;
        path {
          fill: #FFF;
        }
      }
    }
    .img-bg {
      margin: 16px 0;
      text-align: center;
      position: relative;
      cursor: pointer;
      img {
        border-radius: 4px;
        overflow: hidden;
      }
      .pdf-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .name {
      margin-top: auto;
      font-size: 14px;
      line-height: 16px;
      color: #484848;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const CLModalWrapper = styled.div`
  position: relative;
  padding: 40px;
  .title {
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 24px;
    margin-right: 100px;
    line-height: 28px;
    color: ${theme.colors.text};
  }
  .img-bg {
    width: 668px;
    margin: 0 auto;
  }
  .footer {
    margin-top: 40px;
  }
`;
