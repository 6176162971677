import { useRootStore } from "../../stores/storeContext";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component";
import { useRef, useState } from "react";
import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { CLSpiner } from "../shared/cl-spinner";
import { useParams } from "react-router-dom";
import { ClPatientInsuranceInfoWrapper } from "./cl-patient-insurance-info.presents";
import { CLUploadInsuranceCardImages } from "../cl-upload-insurance-card-images";
import { message } from "antd";

export const ClPatientInsuranceInfo = observer(() => {
  const {
    resetForm,
    validateFormFields,
    primaryInsuranceCompany,
    policyNumber,
    policyName,
    groupNumber,
    insurancePhoneNumber,
    secondaryInsuranceCompany,
    secPolicyNumber,
    secGroupNumber,
    secPhoneNumber,
    secPolicyName,
    subscribersFirstName,
    subscribersLastName,
    subscribersRelationship,
    subscribersDOB,
    isLoading,
    saveForm
  } = useRootStore().patientCreateAdditionalModel;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const history: any = useHistory();
  const { patientId }: any = useParams();
  const fileRef = useRef<{ submitFile: Function, validateFile: Function }>(null);

  const isCreate = history?.location?.state?.isCreate;
  const isStudy = history?.location?.state?.isStudy;

  function validateForm() {
    const { errors, isValid } = validateFormFields();
    setErrors(errors);
    return isValid;
  }

  const handleChange = () => {
  };
  const clearData = () => {
    resetForm(true);
    setFormInvalid(false);
    setErrors({});
  };
  const hide = () => {
    clearData();
    history.goBack();
  };
  const onSubmit = async(event: any) => {
    event.preventDefault();
    const key = "updatable";
    const id = patientId || "";
    if (!validateForm()) {
      setFormInvalid(true);
    } else {
      try {
        setFormInvalid(false);
        setGlobalSpiner(true);
        const response = await saveForm();
        if (response) {
          await fileRef.current?.submitFile(id, !isCreate);
          if (isCreate) {
            setTimeout(() => {
              clearData();
              history.push(`/study-create/${id}`);
            }, 100);
          } else {
            clearData();
            message.success({ content: "Insurance information successfully updated!", key, duration: 2 });
          }
          if (isStudy) {
            clearData();
            history.goBack();
            // window.location.reload();
          }
        } else {
          message.error({ content: "Error, Something went wrong!", key, duration: 2 });
        }
        setGlobalSpiner(false);
      } catch (e) {
        message.error({ content: "Error, Something went wrong!", key, duration: 2 });
      }
    }
  };

  return (
    <ClPatientInsuranceInfoWrapper>
      <div className="container">
        <h1>Insurance</h1>
        {isLoading
          ? <div className="spinner"><CLSpiner containerWidth={"100px"}/></div>
          : <div className="form-container" id="study-enrollment">
            <form onSubmit={onSubmit}>
              <div className="study-create-block">
                <h3>Insurance Information</h3>
                <CLDynamicFormComponent
                  item={primaryInsuranceCompany}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={policyNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={policyName}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={groupNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={insurancePhoneNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <div className="w100 border-bottom"></div>
                <h3>Secondary Insurance Information</h3>
                <CLDynamicFormComponent
                  item={secondaryInsuranceCompany}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={secPolicyNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={secPolicyName}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={secGroupNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={secPhoneNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <div className="w100 border-bottom"></div>
                <h3>Subscriber Information</h3>
                <CLDynamicFormComponent
                  item={subscribersFirstName}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={subscribersLastName}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={subscribersRelationship}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={subscribersDOB}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
              </div>
              <div className="w100 border-bottom"></div>
              <CLUploadInsuranceCardImages ref={fileRef} idPatient={patientId || 0} isEdit={!isCreate}/>
              <div className="btn-container">
                <CLBlueButton type="submit" text={"Save Information"} click={() => {
                }}/>
                <CLLightButton type="button" text="Cancel" click={hide}/>
              </div>
            </form>
          </div>
        }
      </div>
    </ClPatientInsuranceInfoWrapper>
  );
});
