import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClPatientInsuranceInfoWrapper = styled.div`
  padding: 40px 20px 140px;
  min-height: 920px;
  h1 {
    flex: 0 0 100%;
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    padding: 0 10px;
  }
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  // .title {
  //   font-weight: normal;
  //   font-size: 24px;
  //   line-height: 28px;
  //   color: ${theme.colors.text};
  // }
  .btn-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    padding: 0 10px;

    div:last-child {
      margin-left: 24px;
    }
  }
  .w100 {
    width: 100% !important;
  }
  h3 {
    flex: 0 0 100%;
    margin: 20px 0;
    padding: 16px 10px 0;
    font-size: 16px;
    line-height: 18px;
  }
  .study-create-block {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding-bottom: 20px;
    & > div {
      width: 50%;
      flex-wrap: wrap;
      padding: 10px;
    }
  }
  .form-block {
    width: 50%;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;

    label {
      font-size: 12px;
      line-height: 14px;
      width: 100%;
      position: relative;
    }

    .optional-flag {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 12px;
      line-height: 14px;
      color: #8A8A8A;
    }

    .checkbox-name {
      font-size: 14px;
      line-height: 16px;
    }

    &.invalid {
      input {
        border: 1px solid ${theme.colors.red};
      }

      .ant-picker {
        border: 1px solid ${theme.colors.red};

        input {
          border: 0;
        }
      }

      .cl-select__control {
        border: 1px solid ${theme.colors.red} !important;
      }
    }
  }
`;
