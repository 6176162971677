import { ISelectOptionsModelSnapShot } from "../../stores/createUser/options.model";
import { validatePhoneHandler } from "../../services/handlers/validate.handler";
import { isRX3Device } from "../../services/handlers/data.handler";

export const ValidateField = (form: any, isEditMode?: boolean): {errors: {[key: string]: string}, isValid: boolean} => {
  const errors: {[key: string]: string} = {};
  let isValid: boolean = true;

  for (const key in form) {
    const field = form[key];
    // reqired field
    if (field.isOptional) {
      continue;
    }
    if (isEditMode && !isRX3Device(form.availableDevice?.value)) {
      if (key === "HPDays" || key === "duration") {
        continue;
      }
    }
    if (field.validateTimeZone) {
      if (!field.value) {
        errors.zip = "Please fill USA zip";
        isValid = false;
      }
    }
    if (field && field.fieldId && !field.isOptional) {
      if (!field.value) {
        errors[field.fieldId] = "Please fill the field";
        isValid = false;
      }
    }
    if (field.type === "number") {
      if (field.value.indexOf("_") + 1) {
        isValid = false;
        errors[field.fieldId] = "Please enter valid phone number";
      }
    }
    if (field.validatePhone) {
      if (!validatePhoneHandler(field.value)) {
        isValid = false;
        errors[field.fieldId] = "Please enter valid phone number";
      }
    }
    if (field.validateZip) {
      if (!isNumber(field.value)) {
        isValid = false;
        errors[field.fieldId] = "Please enter only number";
      } else if (field.value.length !== 5) {
        isValid = false;
        errors[field.fieldId] = "Please enter valid zip";
      }
    }

    if (key === "HPDays") {
      if (form.serviceType.value === "4") {
        if (field.value === "None" || field.value + "" === "0") {
          isValid = false;
          errors[field.fieldId] = "Please fill the field";
        }
      }
    }
  }

  return {
    errors,
    isValid
  };
};

export const rules = (form: any): {errors: {[key: string]: string}, isValid: boolean} => {
  const errors: {[key: string]: string} = {};
  let isValid: boolean = true;

  if (form.PNotify.value <= form.PReport.value) {
    errors[form.PReport.fieldId] = "Field should be less than Pause Notify value";
    errors[form.PNotify.fieldId] = "Field should be more than Pause Report value";
    isValid = false;
  }
  if (form.BNotify.value >= form.BReport.value) {
    errors[form.BNotify.fieldId] = "Field should be less than Bradycardia Report value";
    errors[form.BReport.fieldId] = "Field should be more than Bradycardia Notify value";
    isValid = false;
  }
  if (form.TNotify.value <= form.TReport.value) {
    errors[form.TReport.fieldId] = "Field should be less than Tachycardia Notify value";
    errors[form.TNotify.fieldId] = "Field should be more than Tachycardia Report value";
    isValid = false;
  }
  if (form.BReport.value >= form.TReport.value) {
    errors[form.BReport.fieldId] = "Field should be less than Tachycardia Report value";
    errors[form.TReport.fieldId] = "Field should be more than Bradycardia Report value";
    isValid = false;
  }
  if (+form.PReport.value <= 60 / (+form.BNotify.value)) {
    errors[form.PReport.fieldId] = "Field should be less than Tachycardia Notify value";
    isValid = false;
  }

  return {
    errors,
    isValid
  };
  // PauseTimeUrgent > PauseTimeRoutine
  // BradyRateUrgent < BradyRateRoutine
  // TachyRateUrgent > TachyRateRoutine
  // PauseTimeRoutine > 2 * 60 / BradyRateUrgent
  // BradyRateRoutine < TachyRateRoutine
  // All Tachy > All Brady
};

export type Operator = ">" | "<" | "60";
const operation = {
  ">": (a: number, b: number) => a >= b,
  "<": (a: number, b: number) => a <= b,
  "60": (a: number, b: number) => a < 60 / b
};

export const dynamicValidationFunction = (
  baseFileds: any,
  targetField: any,
  operator: Operator,
  optionsBase?: ISelectOptionsModelSnapShot[]
) => {
  if (baseFileds.value) {
    const options = optionsBase || JSON.parse(JSON.stringify(targetField.optionsSnapshot));
    options.map((option: ISelectOptionsModelSnapShot) => {
      if (option.value === "None") {
        option.isDisabled = false;
      } else {
        if (option.id && operation[operator](+option.id, +baseFileds.value)) { // +option.id > baseValue.id
          option.isDisabled = true;
        } else {
          if (!optionsBase) {
            option.isDisabled = false;
          }
        }
      }
    });
    if (!optionsBase) targetField.setOptions(options);
  }
};

export const isNumber = (str: string) => {
  const pattern = new RegExp(/^[0-9\b]+$/);
  return pattern.test(str);
};
