import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLStudyCreateWrapper = styled.div`
  position: relative;
  padding: 20px 0px;
  background: #FFF;
  width: 888px;
  margin: 0 auto;
  
  .radio-field {
    width: 100%;
    margin: 6px 10px;
  }
  
  #print-mode {
    display: none;
    #info-header {
      padding-left: 0 !important;
      margin-bottom: 30px !important;
      border-top: none !important;
      display: block;
      background: none;
      box-shadow: none;
    }
   
    .nav-wraper {
      display: none !important;
    }
    &.print {
      display: block;
    }
  }
  
  .device-warning {
    margin: 10px 10px 20px;
    padding: 4px 5px;
    color: ${theme.colors.red};
  }
  
  .container {
    padding: 20px 0 20px;
    display: flex;
    //border-bottom: 1px solid ${theme.colors.background};
    max-width: 1024px;
    margin: 0 auto;
    // align-items: center;
    flex-wrap: wrap;
    &.no-shadow {
      padding: 40px;
      width: 940px;
    }
    .calendar {
      width: 250px;
      height: 250px;
      position: absolute;
      right: 0;
      top: 0;
      transform: scale(0.9) translate(110%, 24px);

      .ant-picker-calendar-date-today::before {
        border: none;
      }

    }

    .month {
      text-align: center;
    }
  }

  .back {
    position: absolute;
    top: 42px;
    left: 20px;
  }

  .pagination {
    padding-bottom: 7px;
    margin-left: auto;
  }

  h1 {
    flex: 0 0 100%;
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    padding: 0 10px;
  }

  .study-create-block {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    &.block-form {
      margin-top: 30px;
    }

    .spiner-container {
      margin-top: 32px;
      padding-left: 10px;
      height: 60px;
      width: 275px;
      position: relative;
    }
  }

  .button-holder {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  h3 {
    flex: 0 0 100%;
    margin: 30px 0 15px;
    padding: 0 10px;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    &.notes{
      color: ${theme.colors.placeholder};
    }
  }

  .form-block {
    width: 33%;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;

    label {
      font-size: 12px;
      line-height: 14px;
      width: 100%;
      position: relative;
    }

    .optional-flag {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 12px;
      line-height: 14px;
      color: #8A8A8A;
    }

    .checkbox-name {
      font-size: 14px;
      line-height: 16px;
    }

    &.invalid {
      input {
        border: 1px solid ${theme.colors.red};
      }

      .ant-picker {
        border: 1px solid ${theme.colors.red};

        input {
          border: 0;
        }
      }

      .cl-select__control {
        border: 1px solid ${theme.colors.red} !important;
      }
    }
  }

  .hide-on-print {
    &.hide {
    display: none !important;
    }
  }
  .from-group-state {
    width: 33%;
    flex-wrap: wrap;
    padding: 0;
    display: flex;

    .form-block {
      width: 100%;
    }

    .form-control-state {
      width: 50%;
      flex-wrap: wrap;
    }

    .form-control-zip {
      width: 50%;
      flex-wrap: wrap;
    }
  }

  .ant-picker {
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #E5E5E5;
    color: #484848;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px;
    width: 100%;
  }

  .w100 {
    width: 100%;
    &.border-bottom {
      border-bottom: 1px solid #E5E5E5 !important;
      margin: 20px 0 0;
    }
  }
`;
