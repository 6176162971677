import { useRootStore } from "../../stores/storeContext";
import moment from "moment";
import { PatientStudyInfoStyle } from "./cl-patient-study-info.presents";
import API from "../../boot/Api";
import * as Types from "../../services/api/api.types";
import { useEffect, useState } from "react";
import CheckedIcon from "../../assets/icons/checked.svg";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

const getClinicsPhysicians = async() => {
  const result = API.get("ClinicPhysicians");
  return await Types.getResponse(result);
};

export const ClPatientStudyInfo = () => {
  const { patient, patientBase, getState } = useRootStore().patientInfoStore;
  const additionalClinics = useRootStore().patientCreateAdditionalModel;
  const [clinicsPhysicians, setClinicsPhysicians] = useState([]);
  const [fullMode, setFullMode] = useState(false);
  const [state, setState] = useState("");
  const usagePatient = patient && patient.Patient || patientBase;

  const isInsuranceInfoAvailable = isFeatureFlagActive("insuranceInfo");

  useEffect(() => {
    const getAdditionalClinics = (response: any) => {
      return response
        .map((item: any) => ({
          value: item.Name,
          label: item.Name,
          id: item.Id + ""
        }));
    };

    getClinicsPhysicians().then((response: any) => {
      const arr = response.data.map((item: any) => ({
        value: item.Name + " " + item.LastName,
        label: item.Name + " " + item.LastName,
        id: item.Id + "",
        additionalClinics: getAdditionalClinics(item.AdditionalClinics)
      }));
      setClinicsPhysicians(arr);
    });

    getState(patientBase.IDState)
      .then((result: string) => { setState(result); });
  }, []);
  const getGender = (type: string): string => {
    if (type === "M") {
      return "Male";
    } else if (type === "F") {
      return "Female";
    } else {
      return "Other";
    }
  };
  const getPhysiciansById = (id: string | number) => {
    if (clinicsPhysicians && clinicsPhysicians.length) {
      const active: any = clinicsPhysicians.find((item: any) => +item.id === +id);
      return active;
    } else {
      return null;
    }
  };

  const getPhysiciansName = (idPhysicians: string | number) => {
    const physician = getPhysiciansById(idPhysicians);
    if (physician) {
      return physician.value;
    } else {
      return "-";
    }
  };

  const getInsuranceInfo = (name: string) => {
    if (additionalClinics[name]) {
      return additionalClinics[name].value || "-";
    } else {
      return "-";
    }
  };

  const getPhysiciansClinic = (idPhysicians: string | number, idClinic: string | number) => {
    const physician = getPhysiciansById(idPhysicians);
    if (physician) {
      const findClinic = physician.additionalClinics
        .find((item: any) => item.id.toString() === idClinic.toString());
      if (findClinic) {
        return findClinic.value;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };
  const ICD10List = usagePatient.IndForMonitor.split(";");

  const renderPatientInfo = () => {
    return (
      <div className="block">
        <h1>
          Patient Information
        </h1>
        <div className="row">
          <div className="field">
            <div className="label">Full Name</div>
            <div className="value">{usagePatient.Name + " " + usagePatient.LastName}</div>
          </div>
          <div className="field">
            <div className="label">Id</div>
            <div className="value">{usagePatient.PatientIDClinic || "-"}</div>
          </div>
          <div className="field">
            <div className="label">Date of Birth</div>
            <div className="value">{moment(usagePatient.DOB).format("MMM Do YYYY") || "-"}</div>
          </div>
          <div className="field">
            <div className="label">Gender</div>
            <div className="value">{getGender(usagePatient.Gender) || "-"}</div>
          </div>
        </div>
        {!fullMode && <div className="view">
          <span onClick={() => setFullMode(true)}>View More</span>
        </div>}
      </div>
    );
  };

  const renderContactInformation = () => {
    return (
      <div className="block">
        <h2>
          Contact Information
        </h2>
        <div className="row">
          <div className="field">
            <div className="label">Street Address</div>
            <div className="value">{usagePatient.Address || "-"}</div>
          </div>
          <div className="field">
            <div className="label">City</div>
            <div className="value">{usagePatient.City || "-"}</div>
          </div>
          <div className="field">
            <div className="label">State/Territory</div>
            <div className="value">{state}</div>
          </div>
          <div className="field">
            <div className="label">Zip Code</div>
            <div className="value">{usagePatient.Zip || "-"}</div>
          </div>
        </div>

        <div className="row">
          <div className="field">
            <div className="label">Timezone</div>
            <div className="value">{usagePatient.TimeZoneStandardName || "-"}</div>
          </div>
          <div className="field">
            <div className="label">Phone Number</div>
            <div className="value">{usagePatient.Contact1 || "-"}</div>
          </div>
          <div className="field">
            <div className="label">Alternative Phone Number:</div>
            <div className="value">{usagePatient.Contact2 || "-"}</div>
          </div>
          <div className="field">
            <div className="label">Other Contacts:</div>
            <div className="value">{usagePatient.Contact3 || "-"}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderIndications = () => {
    return (
      <div className="block">
        <h2>
          Indications for monitoring:
        </h2>
        <div className="row">
          <div className="field w100">
            <div className="label">ICD-10 Code</div>
            <div className="value">
              {ICD10List.map((ICD10: string) => (
                <div>- {ICD10}</div>
              ))}</div>
          </div>
          {usagePatient.CardiacDevice === "Other" && <div className="field">
            <div className="label">
              Patient is Paced <span className="is-paced"><img alt="icon selected" src={CheckedIcon}></img></span>
            </div>
          </div>}
        </div>
      </div>
    );
  };

  const renderClinicInformation = () => {
    return (
      <div className="block">
        <h2>
          Clinic information:
        </h2>
        <div className="row">
          <div className="field">
            <div className="label">Ordering Clinic:</div>
            <div className="value">{getPhysiciansClinic(usagePatient.IDPhysicianOrdering,
              usagePatient.IDPhysicianOrderingClinic)}</div>
          </div>
          <div className="field">
            <div className="label">Ordering Physician:</div>
            <div className="value">{getPhysiciansName(usagePatient.IDPhysicianOrdering)}</div>
          </div>
          <div className="field">
            <div className="label">Reading Clinic:</div>
            <div className="value">{getPhysiciansClinic(usagePatient.IDPhysicianReading,
              usagePatient.IDPhysicianReadingClinic)}</div>
          </div>
          <div className="field">
            <div className="label">Reading Physician:</div>
            <div className="value">{getPhysiciansName(usagePatient.IDPhysicianReading)}</div>
          </div>
        </div>
        {!isInsuranceInfoAvailable && fullMode && <div className="view">
          <span onClick={() => setFullMode(false)}>View Less</span>
        </div>}
      </div>
    );
  };

  const renderInsuranceInformation = () => {
    if (isInsuranceInfoAvailable) {
      return (
        <div className="block">
          <h2>
            Insurance information:
          </h2>
          <div>
            <div className="row">
              <div className="field">
                <div className="label">Primary insurance company:</div>
                <div className="value">{getInsuranceInfo("primaryInsuranceCompany")}</div>
              </div>
              <div className="field">
                <div className="label">Member ID:</div>
                <div className="value">{getInsuranceInfo("policyNumber")}</div>
              </div>
              <div className="field">
                <div className="label">Group number:</div>
                <div className="value">{getInsuranceInfo("groupNumber")}</div>
              </div>
              <div className="field">
                <div className="label">Primary insurance phone number:</div>
                <div className="value">{getInsuranceInfo("insurancePhoneNumber")}</div>
              </div>
            </div>
            <div className="row">
              <div className="field">
                <div className="label">Subscribers first name:</div>
                <div className="value">{getInsuranceInfo("subscribersFirstName")}</div>
              </div>
              <div className="field">
                <div className="label">Subscribers last name:</div>
                <div className="value">{getInsuranceInfo("subscribersLastName")}</div>
              </div>
              <div className="field">
                <div className="label">Subscribers relationship to patient:</div>
                <div className="value">{getInsuranceInfo("subscribersRelationship")}</div>
              </div>
              <div className="field">
                <div className="label">Subscribers DOB:</div>
                <div className="value">{getInsuranceInfo("subscribersDOB")}</div>
              </div>
            </div>
            {fullMode && <div className="view">
              <span onClick={() => setFullMode(false)}>View Less</span>
            </div>}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <PatientStudyInfoStyle>
      {usagePatient && (<div className="info">
        {renderPatientInfo()}
        {fullMode && <div>
          {renderContactInformation()}
          {renderIndications()}
          {renderClinicInformation()}
          {renderInsuranceInformation()}
        </div>}
      </div>)}
    </PatientStudyInfoStyle>
  );
};
