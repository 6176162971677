import { observer } from "mobx-react-lite";
import {
  CLPatiensCreateModalWrapper,
  CLPatiensCreateWrapper
} from "./cl-patient-create.presents";
import { useRootStore } from "../../stores/storeContext";
import { getSnapshot } from "mobx-state-tree";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component";
import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import { isNumber, ValidateField } from "./cl-patient-create.service";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import { message } from "antd";
import { ClMapsAutocomplete } from "../cl-maps-autocomplete/cl-maps-autocomplete";
import { CLModal } from "../shared/cl-modal";
import { VQDynamicFormComponent } from "vivaquant-components-library";
import { useLocation } from "react-router-dom";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

const radioReason = {
  name: "Complete insurance information",
  fieldName: "insuranceInformation",
  btnName: "Insurance Information",
  id: 3
};

export const CLPatientCreate = observer((props: { patientData: any }) => {
  const [formInvalid, setFormInvalid] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [isShowAddress, setIsShowAddress] = useState(false);
  const [idPatient, setIdPatient] = useState(props?.patientData?.Id || null);
  const [modalCompliance, setModalCompliance] = useState({ isShow: false });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history: any = useHistory();
  const location = useLocation();
  const isCreateParams = history?.location?.state?.isCreateFlow;
  const isCreateFlow = location.pathname.indexOf("/create-patient") + 1;
  const state = useRootStore().createUserStore;
  const { getDataBasePatient } = useRootStore().patientInfoStore;

  const isInsuranceInfo = isFeatureFlagActive("insuranceInfo");

  useEffect(() => {
    if (props && props.patientData) {
      setIsEditForm(true);
      setIsShowAddress(true);
      state.setPatientInfo(props.patientData);
    }
    if (isEditForm) {
      state.readingReferringClinical.setIsOptional(false);
      state.referringClinical.setIsOptional(false);
    }
  }, []);

  // ACTIONS
  const handleChange = () => {
    // event.preventDefault();
    // console.log(event, item)
  };

  const handleChangeZip = useCallback((event: any) => {
    event.preventDefault();
    HandleZip(event.target.value);
  }, [state]);

  const handleChangePhysican = (event: any, item: any, name = "referringClinical") => {
    const snapOption = item.optionsSnapshot;
    const active = snapOption.find((elem: any) => elem.value === item.value);
    if (active.additionalClinics && state[name]) {
      state[name].setOptions && state[name].setOptions(active.additionalClinics);
    }
    if (name === "referringClinical" && !state.readingPhysician.value) {
      state.setDefaultValueByKey("readingPhysician", {
        value: active.value,
        label: active.label
      });
      const readingClinicName = "readingReferringClinical";
      state[readingClinicName].setOptions && state[readingClinicName].setOptions(active.additionalClinics);
    }
  };

  const handleChangePhysicanClinic = (event: any, item: any, name = "referringClinical") => {
    const snapOption = item.optionsSnapshot;
    const active = snapOption.find((elem: any) => elem.value === item.value);
    if (name === "referringClinical" && !state.readingReferringClinical.value) {
      state.setDefaultValueByKey("readingReferringClinical", {
        value: active.value,
        label: active.label
      });
    }
  };
  const saveChanges = () => {};
  const showAddressDetails = () => {
    setIsShowAddress(true);
  };
  const handleChangeAutocomplete = (address: any) => {
    state.streetAddress.setValue(address.street_number + " " + address.street);
    state.city.setValue(address.city || address.state);
    const activeSate = state.state.options.find((st: any) => st.value.toLowerCase() === address.state.toLowerCase());
    state.state.setValue(activeSate.value);
    activeSate && state.setDefaultValueByKey("state", {
      value: activeSate.value,
      label: activeSate.label
    });
    state.zip.setValue(address.postal_code);
    HandleZip(address.postal_code);
  };
  const cancelChanges = (event: any) => {
    event.preventDefault();
    state.reset();
    if (history.location.state?.activeCategory) {
      history.push("/patients-list", { activeCategory: history.location.state?.activeCategory });
    } else {
      history.goBack();
    }
  };
  const backClicked = () => {
    state.reset();
    if (history.location.state?.activeCategory) {
      history.push("/patients-list", { activeCategory: history.location.state?.activeCategory });
    } else {
      if (isCreateParams) {
        history.push("/patients-list");
      } else {
        history.goBack();
      }
    }
  };

  const openInsuranceInformation = (Id: any) => {
    setIsEditForm(true);
    setIdPatient(Id);
    state.reset();
    history.push(`/insurance-info/${Id}`, { isCreate: true });
  };

  const handleCreatePatientFlow = async(Id: any, response: any) => {
    const key = "updatable";
    const result = await getDataBasePatient(Id);
    history.replace("/edit-patient/" + Id, { patientData: result.data });
    setTimeout(() => {
      if (response) {
        message.success({ content: "Patient successfully created!", key, duration: 2 });
        if (isInsuranceInfo) {
          openInsuranceInformation(Id);
        } else {
          state.reset();
          history.push(`/study-create/${Id}`);
        }
      } else {
        message.error({ content: "Error, Something went wrong!", key, duration: 2 });
      }
    }, 100);
  };

  const handlingResponse = (response: any) => {
    const Id = idPatient || response;
    const key = "updatable";
    setIsLoading(false);

    if (isCreateFlow) {
      handleCreatePatientFlow(Id, response);
    } else if (isCreateParams) {
      if (response) {
        message.success({ content: "Patient successfully created!", key, duration: 2 });
        if (isInsuranceInfo) {
          openInsuranceInformation(Id);
        } else {
          state.reset();
          history.push(`/study-create/${Id}`);
        }
      } else {
        message.error({ content: "Error, Something went wrong!", key, duration: 2 });
      }
    } else {
      if (Id) {
        state.reset();
        history.goBack();
        message.success({ content: "Patient successfully updated!", key, duration: 2 });
      } else {
        message.error({ content: "Error, Something went wrong!", key, duration: 2 });
      }
    }
  };

  const sendData = () => {
    setFormInvalid(false);
    const key = "updatable";
    setIsLoading(true);
    message.loading({ content: "Loading...", key });
    if (isEditForm) {
      state.updatePatient(idPatient)
        .then((response: any) => {
          handlingResponse(response);
        });
    } else {
      state.saveForm()
        .then((response: any) => {
          handlingResponse(response);
        });
    }
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (!validateForm(getSnapshot(state))) {
      setFormInvalid(true);
      setIsShowAddress(true);
      message.error({ content: "Please fill the fields!", duration: 2 });
    } else {
      sendData();
    }
  };

  function validateForm(form: any) {
    const { errors, isValid, isCompliance } = ValidateField(form);
    setErrors(errors);
    if (isValid && isCompliance) {
      setModalCompliance({ isShow: true });
      return false;
    }
    return isValid;
  }

  function HandleZip(value: string) {
    let isValid = false;
    if (isNumber(value) && value.length === 5) {
      isValid = true;
    }
    if (isValid) {
      state.timeZone.checkZip && state.timeZone.checkZip(value).then((res: any) => {
        if (res && (res.DST || res.DST === 0)) {
          state.DST.setValue(res.DST + "");
          const errors2: any = {};
          errors2.zip = "";
          errors2.DST = "";
          setErrors((prevState) => ({ ...prevState, ...errors2 }));
        } else {
          const errors2: any = {};
          errors2.zip = "Please enter valid zip";
          setErrors((prevState) => ({ ...prevState, ...errors2 }));
        }
      });
    }
  }
  const updateInsuranceInfo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/insurance-info/${idPatient}`, { isCreate: false });
  };

  return (
    <CLPatiensCreateWrapper>
      <span className="back" onClick={backClicked}> <img alt="back" src={ArrowLeftIcon}></img></span>
      <div className="container">
        <h1>{isEditForm ? "Edit Patient" : "Create Patient"}</h1>
        <form onSubmit={onSubmit}>
          <div className="patient-create-block">
            <h3>Personal Information</h3>
            <CLDynamicFormComponent item={state.firstName} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.middleName} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.lastName} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>

            <CLDynamicFormComponent item={state.birthDate} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.gender} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.patientId} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>

            <h3>Contact Information</h3>
            <div style={{ width: "100%", padding: "10px" }}>
              <ClMapsAutocomplete onChange={handleChangeAutocomplete}></ClMapsAutocomplete>
              {!isShowAddress && <div className="span">
                <span className="link" onClick={showAddressDetails}>Type in address</span>
              </div>}
            </div>
            {isShowAddress && <>
              <CLDynamicFormComponent item={state.streetAddress} errors={errors} isFormInvalid={formInvalid}
                handleChange={handleChange}></CLDynamicFormComponent>
              <CLDynamicFormComponent item={state.city} errors={errors} isFormInvalid={formInvalid}
                handleChange={handleChange}></CLDynamicFormComponent>
              <CLDynamicFormComponent item={state.state} errors={errors} isFormInvalid={formInvalid}
                handleChange={handleChange}></CLDynamicFormComponent>
              <CLDynamicFormComponent item={state.zip} errors={errors} isFormInvalid={formInvalid}
                handleChange={handleChangeZip}></CLDynamicFormComponent>
              <CLDynamicFormComponent item={state.timeZone} errors={errors} isFormInvalid={formInvalid}
                handleChange={handleChange} disabled={true}></CLDynamicFormComponent>
            </>}
            <div className="w100"></div>
            <CLDynamicFormComponent item={state.phone} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.alternativePhone} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.otherContacts} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>

            <div className="w100"></div>
            <CLDynamicFormComponent item={state.isPaced} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>

            <h3>Information for monitoring</h3>
            <VQDynamicFormComponent customClass="width-33" item={state.ICD10Code} errors={errors}
              isFormInvalid={formInvalid} handleChange={handleChange}></VQDynamicFormComponent>
            <CLDynamicFormComponent item={state.medications} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>

            {/* <CLICD10 item={state.ICD10Code} errors={errors} isFormInvalid={formInvalid} */}
            {/*             handleChange={handleChange}></CLICD10> */}

            <h3>Clinic information</h3>
            <CLDynamicFormComponent item={state.orderingPhysician} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChangePhysican}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.referringClinical} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChangePhysicanClinic}></CLDynamicFormComponent>
            <div className="w100"></div>
            <CLDynamicFormComponent item={state.readingPhysician} errors={errors} isFormInvalid={formInvalid}
              handleChange={(event: any, item: any) => {
                handleChangePhysican(event, item, "readingReferringClinical");
              }}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.readingReferringClinical} errors={errors} isFormInvalid={formInvalid}
              handleChange={(event: any, item: any) => {
                handleChangePhysicanClinic(event, item, "readingReferringClinical");
              }}></CLDynamicFormComponent>
          </div>
          <div className="patient-create-block">
            <div className="button-holder">
              <CLBlueButton
                text="Save Patient"
                isDisabled={isLoading}
                click={saveChanges}/>
            </div>
            {!isCreateFlow && isInsuranceInfo
              ? <div className="button-holder">
                <CLBlueButton text={radioReason.btnName} click={updateInsuranceInfo}/>
              </div>
              : null}
            <div className="button-holder">
              <CLLightButton text="Cancel" click={cancelChanges}/>
            </div>
          </div>
        </form>
      </div>
      {modalCompliance.isShow
        && <CLModal
          title="Text Phone Number"
          show={modalCompliance.isShow}
          dialogClassName="modal-dialog-small"
          handleHideModal={() => {
            setModalCompliance({ isShow: false });
          }}
        >
          <CLPatiensCreateModalWrapper>
            <div className="modal-container">
              <div className="title">
                Text Phone Number
              </div>
              <p className="description">
                The field Text Phone Number is empty, Patient will not receive compliance alerts.
              </p>
              <div className="footer">
                <div className="btn-wrapper">
                  <CLLightButton
                    click={() => setModalCompliance({ isShow: false })}
                    text="Cancel"
                    width="200px"></CLLightButton>
                </div>
                <div className="btn-wrapper">
                  <CLBlueButton
                    click={() => {
                      setModalCompliance({ isShow: false });
                      sendData();
                    }}
                    text="Save"
                    width="200px"></CLBlueButton>
                </div>
              </div>
            </div>
          </CLPatiensCreateModalWrapper>
        </CLModal>}
    </CLPatiensCreateWrapper>
  );
});
